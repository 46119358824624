import React, { PureComponent } from 'react'
import classNames from 'classnames'

import './styles.css'

export default class Text extends PureComponent {
  render() {
    const className = classNames({
      Text,
      'Text--alternative': this.props.alt,
    })

    return <p className={className}>{this.props.children}</p>
  }
}
