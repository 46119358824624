import React, { PureComponent } from 'react'
import Moment from 'moment';

import './styles.css'

import Text from '../Text'
import Link from '../Link'
import Logo from '../Logo'
import Input from '../Input'
import SocialIcon from '../SocialIcon'
import Form from '../MailchimpForm'

export default class Footer extends PureComponent {
  render() {
    return (
      <div className="Footer">
        <hr className="Footer-separator" />
        <div className="Footer-content">
          <div className="Footer-column">
            <a href="/">
              <div className="Footer-logo">
                <Logo />
              </div>
            </a>
            <div className="Footer-desktopCopyrights">
              <div className="Footer-links">
                <Text>
                  <Link href="/privacy">Privacy Policy</Link> and{' '}
                  <Link href="/terms">Terms of Use</Link>
                </Text>
              </div>
              <p className="Footer-copyrights">
                {`Keyruptive © ${Moment().year()} All rights reserved`}
              </p>
            </div>
          </div>
          <div className="Footer-column">
            <div className="Footer-button">
              <p className="Footer-title">Stay up to date</p>
              <Form>
                <Input type="email" placeholder="Your email" button alt>
                  Join
                </Input>
              </Form>
            </div>
            <div className="Footer-icons">
              <div className="Footer-icon">
                <SocialIcon
                  name="facebook"
                  href="https://www.facebook.com/keyruptive"
                  alt
                />
              </div>
              <div className="Footer-icon">
                <SocialIcon
                  name="linkedin"
                  href="https://www.linkedin.com/company/keyruptive"
                  alt
                />
              </div>
              <div className="Footer-icon">
                <SocialIcon
                  name="instagram"
                  href="https://www.instagram.com/keyruptive_official"
                  alt
                />
              </div>
              <div className="Footer-icon">
                <SocialIcon
                  name="medium"
                  href="https://medium.com/keyruptive"
                  alt
                />
              </div>
              <div className="Footer-icon">
                <SocialIcon
                  name="telegram"
                  href="https://www.t.me/keyruptive"
                  alt
                />
              </div>
              <div className="Footer-icon">
                <SocialIcon
                  name="twitter"
                  href="https://twitter.com/keyruptive"
                  alt
                />
              </div>
            </div>
            <div className="Footer-mobileCopyrights">
              <div className="Footer-links">
                <Text>
                  <Link href="/privacy">Privacy Policy</Link> and{' '}
                  <Link href="/terms">Terms of Use</Link>
                </Text>
              </div>
              <p className="Footer-copyrights">
                {`Keyruptive © ${Moment().year()} All rights reserved`}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
