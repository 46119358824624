import React, { PureComponent } from 'react'
import classNames from 'classnames'

import './styles.css'

export default class Button extends PureComponent {
  render() {
    const className = classNames({
      Button,
      'Button--outline': this.props.outline,
      'Button--alternative': this.props.alt,
    })

    if (this.props.href) {
      return (
        <a
          href={this.props.href}
          className={className}
          target={this.props.target}
        >
          <div className="Button-content">{this.props.children}</div>
        </a>
      )
    }

    return (
      <div className={className}>
        <button type={this.props.type} className="Button-content">
          {this.props.children}
        </button>
      </div>
    )
  }
}
