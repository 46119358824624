import React, { Component } from 'react'

import Head from '../head'

import './reset.css'
import './normalize.css'
import './styles.css'

export default class Layout extends Component {
  render() {
    return (
      <div className="Layout">
        <Head title={this.props.title} />
        <div className="Layout-content">{this.props.children}</div>
      </div>
    )
  }
}
