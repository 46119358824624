import React, { PureComponent } from 'react'

import './styles.css'

import Image from './logo.svg'
import TextOnly from './text_only.svg'

export default class Logo extends PureComponent {
  render() {
    if (this.props.textOnly) {
      return <img src={TextOnly} alt="Logo" />
    } else {
      return <img src={Image} alt="Logo" />
    }
  }
}
