import React, { PureComponent } from 'react'
import './styles.css'
import Button from '../Button'

export default class Input extends PureComponent {
  render() {
    return (
      <div
        className={`Input ${this.props.button ? 'Input--button' : ''} ${
          this.props.alt ? 'Input--alternative' : ''
        }`}
      >
        <div className="Input-container">
          <input
            type={this.props.type}
            name="EMAIL"
            placeholder={this.props.placeholder}
            size={this.props.size || '28'}
          />
          {this.props.button && (
            <Button type="submit" alt={this.props.alt}>
              {this.props.children}
            </Button>
          )}
        </div>
      </div>
    )
  }
}
