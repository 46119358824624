import React, { PureComponent } from 'react'

import './styles.css'

export default class Link extends PureComponent {
  render() {
    return (
      <a href={this.props.href} className="Link">
        {this.props.children}
      </a>
    )
  }
}
