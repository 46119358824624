import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

export default class Head extends Component {
  _render = data => {
    const { meta, lang } = this.props
    const {
      site: { siteMetadata },
    } = data

    const title = this.props.title || siteMetadata.title
    const description = this.props.description || siteMetadata.description
    const metaDescription = description || siteMetadata.description
    const image = `${siteMetadata.url}${siteMetadata.image}`

    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: `image`,
            content: image,
          },
          {
            property: `og:url`,
            content: siteMetadata.url,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: `${siteMetadata.url}${siteMetadata.image}`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `twitter:image`,
            content: `${siteMetadata.url}${siteMetadata.image}`,
          },
        ]
          .concat(
            siteMetadata.keywords.length > 0
              ? {
                  name: `keywords`,
                  content: siteMetadata.keywords.join(`, `),
                }
              : []
          )
          .concat(meta)}
      >
        <link rel="stylesheet" href="https://use.typekit.net/zti4qdd.css" />
      </Helmet>
    )
  }

  render() {
    return <StaticQuery query={detailsQuery} render={this._render} />
  }
}

Head.defaultProps = {
  lang: `en`,
  meta: [],
}

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string,
}

const detailsQuery = graphql`
  query MetadataQuery {
    site {
      siteMetadata {
        title
        description
        author
        image
        url
        keywords
      }
    }
  }
`
