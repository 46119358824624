import React, { PureComponent } from 'react'

import './styles.css'

import Telegram from './icons/telegram.svg'
import TelegramAlt from './icons/telegram_alt.svg'
import Facebook from './icons/facebook.svg'
import FacebookAlt from './icons/facebook_alt.svg'
import Twitter from './icons/twitter.svg'
import TwitterAlt from './icons/twitter_alt.svg'
import Medium from './icons/medium.svg'
import MediumAlt from './icons/medium_alt.svg'
import Linkedin from './icons/linkedin.svg'
import LinkedinAlt from './icons/linkedin_alt.svg'
import Behance from './icons/behance.svg'
import BehanceAlt from './icons/behance_alt.svg'
import Dblp from './icons/dblp.svg'
import DblpAlt from './icons/dblp_alt.svg'
import Github from './icons/github.svg'
import GithubAlt from './icons/github_alt.svg'
import Instagram from './icons/instagram.svg'
import InstagramAlt from './icons/instagram_alt.svg'

const icons = {
  facebook: {
    logo: Facebook,
    alt: FacebookAlt,
  },
  twitter: {
    logo: Twitter,
    alt: TwitterAlt,
  },
  telegram: {
    logo: Telegram,
    alt: TelegramAlt,
  },
  medium: {
    logo: Medium,
    alt: MediumAlt,
  },
  linkedin: {
    logo: Linkedin,
    alt: LinkedinAlt,
  },
  behance: {
    logo: Behance,
    alt: BehanceAlt,
  },
  dblp: {
    logo: Dblp,
    alt: DblpAlt,
  },
  github: {
    logo: Github,
    alt: GithubAlt,
  },
  instagram: {
    logo: Instagram,
    alt: InstagramAlt,
  },
}

var classNames = require('classnames')

export default class SocialIcon extends PureComponent {
  render() {
    const { name, alt } = this.props

    const iconName = classNames({
      facebook: name === 'facebook',
      twitter: name === 'twitter',
      telegram: name === 'telegram',
      medium: name === 'medium',
      linkedin: name === 'linkedin',
      behance: name === 'behance',
      dblp: name === 'dblp',
      github: name === 'github',
      instagram: name === 'instagram',
    })

    const icon = {
      logo: alt ? icons[iconName].alt : icons[iconName].logo,
      hover: alt ? icons[iconName].logo : icons[iconName].alt,
    }

    return (
      <a href={this.props.href} target="_blank" rel="noopener noreferrer">
        <img className="SocialIcon" src={icon.logo} alt={iconName} />
      </a>
    )
  }
}
