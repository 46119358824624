import React, { PureComponent } from 'react'

export default class Logo extends PureComponent {
  render() {
    return (
      <form
        action="https://keyruptive.us20.list-manage.com/subscribe/post?u=1900e7a050b095cef7d3fafab&amp;id=09eecaaae3"
        className={this.props.className}
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        noValidate
      >
        {this.props.children}
      </form>
    )
  }
}
